import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const Counter = () => {
  const { allContentfulPost, allContentfulCategory } = useStaticQuery(graphql`
    query {
      allContentfulPost {
        totalCount
        nodes {
          title
          author
          draft
          tags
          originalLanguage {
            title
          }
          serie {
            title
          }
          gallery {
            title
          }
          vPreview {
            title
          }
          tPreview {
            title
          }
        }
      }
      allContentfulSerie {
        totalCount
      }
      allContentfulCategory {
        totalCount
      }
    }
  `);

  const allPosts = allContentfulPost.nodes;
  const allPublishedPosts = allPosts.filter((item) => item.draft !== true);

  const allSeries = [
    ...new Set(allPublishedPosts.map((item) => item.serie?.title)),
  ].filter(Boolean).length;

  const allCategories = allContentfulCategory.totalCount;
  const allTags = [
    ...new Set(allPublishedPosts.map((item) => item.tags).flat()),
  ].length;
  const allAuthors = [...new Set(allPublishedPosts.map((item) => item.author))]
    .length;
  const allLinks =
    allPublishedPosts.filter((i) => i.tPreview).length +
    allPublishedPosts.filter((i) => i.vPreview).length;
  const allImages =
    allPublishedPosts
      .map((items) => items.gallery.length)
      .reduce((a, b) => a + b) + allSeries;

  const allLanguages =
    [
      ...new Set(
        allPublishedPosts
          .filter((item) => item.originalLanguage)
          .map((item) => item.originalLanguage.title)
      ),
    ].length + 1;

  return (
    <div className="grid grid-cols-3">
      <div className="col-span-2 dark:text-white">Autoren:</div>
      <div className="dark:text-white"> {allAuthors}</div>
      <div className="col-span-2 dark:text-white">Beiträge:</div>
      <div className="dark:text-white"> {allPublishedPosts.length}</div>
      <div className="col-span-2 dark:text-white">Kategorien:</div>{" "}
      <div className="dark:text-white">{allCategories}</div>
      <div className="col-span-2 dark:text-white">Reihen:</div>{" "}
      <div className="dark:text-white">{allSeries}</div>
      <div className="col-span-2 dark:text-white">Tags: </div>
      <div className="dark:text-white">{allTags}</div>
      <div className="col-span-2 dark:text-white">Bilder:</div>
      <div className="dark:text-white"> {allImages}</div>
      <div className="col-span-2 dark:text-white">Links:</div>{" "}
      <div className="dark:text-white">{allLinks}</div>
      <div className="col-span-2 dark:text-white">Sprachen:</div>{" "}
      <div className="dark:text-white">{allLanguages}</div>
    </div>
  );
};

export default Counter;
