import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { graphql, Link } from "gatsby";
import SiteMetadata from "../../components/SiteMetaData";
import { GatsbyImage } from "gatsby-plugin-image";
import Counter from "../../components/Counter";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { checkRole, ConditionalWrapper } from "../../components/Utils";
import { FaChevronRight } from "react-icons/fa";

const VorschauPage = ({ data, location }) => {
  const state = location.state;
  const givenAuthor = state?.author;
  const givenCategory = state?.category;
  const givenYear = state?.year;
  const givenMonth = state?.month;
  const givenKeyword = state?.keyword;
  const givenSerie = state?.serie;
  const cards = data.allContentfulPost.nodes.filter((item) => item.draft);

  const postsPerPage = 15;
  const numberOfPages = Math.ceil(cards.length / postsPerPage);
  const pageNumbers = Array.from(Array(numberOfPages).keys());

  const [activePage, setActivePage] = useState(0);
  const [guideline, setGuideline] = useState(false);

  let pagesWithPosts = [];
  pageNumbers.forEach((item) =>
    pagesWithPosts.push(
      cards.slice(item * postsPerPage, (item + 1) * postsPerPage)
    )
  );

  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(true);
    // document.getElementById("toposts").scrollIntoView();
    window.scrollTo({ top: 0, behavior: `smooth` });
    setTimeout(() => setClicked(false), 500);
  };

  const handleClickGuideline = () => {
    setGuideline(!guideline);
  };

  const { user } = useAuth0();

  const PageNumber = () => {
    return (
      pageNumbers.length > 1 && (
        <div className="my-5">
          {pageNumbers.map((number, key) => (
            <button
              className={`mr-2 p-2 rounded-md ${
                activePage === key
                  ? "bg-gray-300 dark:bg-gray-100"
                  : "bg-gray-100 dark:bg-gray-300"
              }`}
              key={key}
              onClick={() => setActivePage(number)}
            >
              <p className="text-sm dark:text-black">{number + 1}</p>
            </button>
          ))}
        </div>
      )
    );
  };

  const Sidebar = () => {
    return (
      <div
        className={`md:w-1/4 md:pl-2.25 pb-5 md:pb-0 ${
          state?.link ? "md:order-2" : "md:order-2"
        }`}
      >
        <div
          id="toposts"
          className={`rounded-lg shadow-sm p-3 ${
            clicked
              ? "bg-yellow-200 dark:bg-gray-500"
              : "bg-white dark:bg-gray-800"
          }`}
        >
          <div className="md:justify-between items-center">
            <div className="text-xl hidden lg:contents">Vorschau</div>
            {state?.link && (
              <Link
                to="../"
                state={{
                  author: givenAuthor,
                  category: givenCategory,
                  month: givenMonth,
                  year: givenYear,
                  keyword: givenKeyword,
                  serie: givenSerie,
                }}
                className="flex justify-between w-full items-center lg:mt-2"
              >
                <div>Zu den veröffentlichten Beiträgen</div>
                <FaChevronRight className="mr-1" />
              </Link>
            )}
          </div>
          {!checkRole(user, "admin") ? (
            <>
              <p className={`pb-3 lg:mt-3 ${state?.link && "mt-5"}`}>
                Diese Blogeinträge sind noch in der Planung.
              </p>
              <p>
                Du möchtest auf dieser Seite mitwirken? Gastbeiträge sind gerne
                gesehen!{" "}
                <button onClick={handleClickGuideline} className="link">
                  {!guideline ? "Zum Leitfaden" : "Zurück"}
                </button>
              </p>
              {state?.link !== true && (
                <p className="pt-3">
                  Falls du nach bereits veröffentlichten Beiträgen suchst, dann
                  klicke
                  <Link to="/beitraege" className="link">
                    {" "}
                    hier
                  </Link>
                  .
                </p>
              )}
            </>
          ) : (
            state?.link !== true && (
              <Link
                to="../"
                className="flex justify-between w-full items-center lg:mt-2"
              >
                <div>Zu den veröffentlichten Beiträgen</div>
                <FaChevronRight className="mr-1" />
              </Link>
            )
          )}
        </div>
        <div className="hidden md:contents">
          <div className="bg-white rounded-lg shadow-sm p-3 mb-4 mt-4 dark:bg-gray-800">
            <div className="text-xl mb-5">Trivia</div>
            <Counter />
          </div>
        </div>
      </div>
    );
  };

  const GuideLine = () => {
    return (
      <>
        <Sidebar />
        {guideline && (
          <div className="md:w-3/4 md:pr-0.75">
            <div className=" bg-white dark:bg-gray-800 rounded-lg p-3">
              <div className="text-lg font-bold mb-2">
                Leitfaden: Schreiben eines Blogbeitrages
              </div>
              <ul className="list-disc list-outside ml-5">
                <li>Titel (maximal 22 Zeichen)</li>
                <li>Kurzbeschreibung (maximal 40 Zeichen)</li>
                <li>Autor: Echter Vorname oder ein Pseudonym</li>
                <li>
                  Mindestens ein Bild passend zum Thema im Format 16:9
                  (Bildrechte beachten!)
                </li>
                <li>Externe Quellen?</li>
                <li>
                  <p>
                    Text: Keine wissenschaftliche Artikel, kein Lexikoneintrag,
                    keine Texte, die so aussehen, als wären sie einfach von
                    Wikipedia kopiert.
                  </p>
                  <p>
                    Gern gesehen sind Gastbeiträge, die aus einer persönlichen
                    Sicht geschrieben werden. Eigene Erfahrungen oder Meinungen
                    sollen erkennbar sein.
                  </p>
                  <p>
                    Der Blogbeitrag kann gerne auch in einer anderen Sprache als
                    Deutsch verfasst werden. Eine Übersetzung ins Deutsche
                    erfolgt dann in Zusammenarbeit mit den Zuständigen der
                    Webseite.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    );
  };

  const Cards = () => {
    return (
      <ConditionalWrapper
        condition={!checkRole(user, "admin")}
        wrapper={(children) => (
          <button onClick={handleClick} className="focus:outline-none">
            {children}
          </button>
        )}
      >
        <div className="grid grid-cols-3 gap-3">
          {pagesWithPosts[activePage]?.map((item) => (
            <ConditionalWrapper
              condition={checkRole(user, "admin")}
              wrapper={(children) => (
                <Link to={`/beitraege/${item.slug.toLowerCase()}`}>
                  {children}
                </Link>
              )}
            >
              <div className="rounded-lg overflow-hidden transform shadow-sm bg-yellow-200 dark:bg-gray-500 h-full">
                <GatsbyImage
                  image={item.gallery[0].gatsbyImageData}
                  alt="bild"
                />
                <div className="p-2 font-medium text-left">{item.title}</div>
              </div>
            </ConditionalWrapper>
          ))}
        </div>
      </ConditionalWrapper>
    );
  };
  return (
    <Layout>
      <SiteMetadata title="Beiträge" description="Die Beiträge im Überblick" />
      {checkRole(user) && (
        <div className="bg-gray-50 dark:bg-gray-900 pb-6 flex-grow dark:text-white">
          <div className="container">
            <div className="text-center text-4xl py-6 lg:hidden">Vorschau</div>
            <div className="md:flex">
              <GuideLine />
              {!guideline && (
                <div
                  className={`md:w-3/4 md:pr-0.75 ${clicked && "opacity-60"} ${
                    state?.link ? "order-1 md:order-1" : "md:order-1"
                  }`}
                >
                  <Cards />
                  <PageNumber />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default withAuthenticationRequired(VorschauPage);

export const query = graphql`
  query VorschauQuery {
    allContentfulPost(sort: { fields: publishedAt, order: ASC }) {
      nodes {
        title
        draft
        slug
        gallery {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 1000
            aspectRatio: 1.77
          )
        }
      }
    }
  }
`;
